﻿document.addEventListener('DOMContentLoaded', function () {

    if (querySelect.byClass('mainBox')) {
        $('.mainBox').scroll(debounce(function () {
            if ($(this).scrollTop() > 30) {
                $('#sideNavLabel').removeClass('d-md-inline-block');
            } else {
                $('#sideNavLabel').addClass('d-md-inline-block');
            }
        }, .5));
    }

    checkIfClicked(querySelect.byId('mySidenav'), querySelect.byId('sideNavToggle'), { fail: function () { menuToggle && menuToggle(false); } } )
    let contactsTooltip = new Array();
    contactsTooltip.push('<div id="ContactInfoPopup" class="layer_5" style="background: rgba(255,255,255,.95);">');
    contactsTooltip.push('<div class="col-12 p-0">');
    contactsTooltip.push('<div class="card-body p-0">');
    contactsTooltip.push('<div class="row p-0 m-0">');
    contactsTooltip.push('<div class="card-header border-w--1 border-c--light col-12">');
    contactsTooltip.push('<div class="row p-0 m-0">');
    contactsTooltip.push('<div class="px-3 mr-3 text-center"><img src="./Content/images/flag_pl.png"></div>');
    contactsTooltip.push('<div class="text-left"><div class="f-size-2-0 f-weight-x3 text-unselectable cursor-default">Kontakt</div></div>');
    contactsTooltip.push('</div></div>');
    contactsTooltip.push('<div class="w-100 maxh-400 overflow-y--auto">');
    contactsTooltip.push('<span class="py-2 col-12 m-0 p-0">');
    contactsTooltip.push('<div class="col-12 padding-l-2 py-2">');
    contactsTooltip.push('<div class="row p-0 m-0">');
    contactsTooltip.push('<span class="mr-3"><i class="fa fa-globe f-size-1-5" title="Kraj"></i></span>');
    contactsTooltip.push('<span>Polska</span>');
    contactsTooltip.push('</div></div>');
    contactsTooltip.push('<div class="col-12 padding-l-2 py-2">');
    contactsTooltip.push('<div class="row p-0 m-0">');
    contactsTooltip.push('<span class="mr-3"><i class="fa fa-home f-size-1-5" title="Adres"></i></span>');
    contactsTooltip.push('<span>ul. Smukalska 33, Bydgoszcz 85-565</span>');
    contactsTooltip.push('</div></div>');
    contactsTooltip.push('<span class="py-2 col-12 m-0 p-0">');
    contactsTooltip.push('<div class="card-header mb-2 col-12">Dział serwisu:</div>');
    contactsTooltip.push('<div class="col-12 padding-l-2 py-2">');
    contactsTooltip.push('<div class="row p-0 m-0">');
    contactsTooltip.push('<span class="mr-3"><i class="fa fa-phone f-size-1-5" title="Telefon"></i></span>');
    contactsTooltip.push('<span id="ServicePhone" onclick="clipboard.copy(\'ServicePhone\')" ' );
    contactsTooltip.push('class="link" data-value="+48 52 360 44 51">+48 52 360 44 51</span>');
    contactsTooltip.push('</div></div>');
    contactsTooltip.push('<div class="col-12 padding-l-2 py-2">');
    contactsTooltip.push('<div class="row p-0 m-0">');
    contactsTooltip.push('<span class="mr-3"><i class="fa fa-at f-size-1-5" title="E-mail"></i></span>');
    contactsTooltip.push('<span><a href="mailto:serwis@tkomp.pl?Subject=Kontakt%20serwis">serwis@tkomp.pl</a></span>');
    contactsTooltip.push('</div></div>');
    contactsTooltip.push('</span>');
    contactsTooltip.push('<span class="py-2 col-12 m-0 p-0">');
    contactsTooltip.push('<div class="card-header mb-2 col-12">Księgowość:</div>');
    contactsTooltip.push('<div class="col-12 padding-l-2 py-2">');
    contactsTooltip.push('<div class="row p-0 m-0">');
    contactsTooltip.push('<span class="mr-3"><i class="fa fa-phone f-size-1-5" title="Telefon"></i></span>');
    contactsTooltip.push('<span id="OfficePhone" onclick="clipboard.copy(\'OfficePhone\')" ' );
    contactsTooltip.push('class="link" data-value="+48 52 360 44 48">+48 52 360 44 48</span>');
    contactsTooltip.push('</div></div>');
    contactsTooltip.push('<div class="col-12 padding-l-2 py-2">');
    contactsTooltip.push('<div class="row p-0 m-0">');
    contactsTooltip.push('<span class="mr-3"><i class="fa fa-at f-size-1-5" title="E-mail"></i></span>');
    contactsTooltip.push('<span><a href="mailto:sekretariat@tkomp.pl?Subject=Kontaktt%20sekretariat">sekretariat@tkomp.pl</a></span>');
    contactsTooltip.push('</div></div></span></div></div></div></div></div>');
    contactsTooltip = contactsTooltip.join('');

    var successCallback = function () {
        $("#RedirectContact").css("display", "none");
        $("#ContactInfo").css("display", "");
    }
    var failureCallback = function () {
        $("#RedirectContact").css("display", "");
        $("#ContactInfo").css("display", "none");
    }
    var checkViewport = new OnViewport('min',767, successCallback, failureCallback);
    checkViewport.matched(checkViewport.query);

    new Tooltip(document.getElementById("contactToggle"), {
        html: true,
        container: document.getElementById("ContactTooltipContainer"),
        template: '<div class="tooltipjs background-glass--40 p-0 layer_5" role="tooltip"><div class="tooltipjs-arrow"></div><div class="tooltipjs-inner"></div></div>',
        placement: "left",
        title: contactsTooltip,
    });
});

jsReady(function () {
    var isRequiredAppMode = APP_CONFIG.MODE.indexOf("DEV") != -1 ? true : false;
    if (isRequiredAppMode) { $("#AppVersion #VersionNumber").html($("#AppVersion #VersionNumber").html().trim() + APP_CONFIG.VERSION); }
    $("#HomeLink").on('click', function () { redirectToPage(APP_CONFIG.ORIGIN_LOCATION); });
    $("body").on("click", "dropdown-menu-contact", function (event) { $(this).parent().is(".show") && event.stopPropagation(); });

    var newPassword = $("#newPassword");
    var confirmNewPassword = $("#confirmNewPassword");
    var oldPassword = $("#oldPassword");


    function validateNewPassword(password) {
        var regex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$")
        return regex.exec(password)
    }


    newPassword.change(function () {
        var regex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$")
        if (!validateNewPassword(this.value)) {
            newPassword.addClass("is-invalid")
        } else {
            newPassword.removeClass("is-invalid")
            newPassword.addClass("is-valid")
        }
    })

    confirmNewPassword.change(function () {

        if (newPassword.val() != '') {
            if (newPassword.val() == confirmNewPassword.val() && validateNewPassword(newPassword.val())) {
                newPassword.removeClass("is-invalid")
                newPassword.addClass("is-valid")
                confirmNewPassword.removeClass("is-invalid")
                confirmNewPassword.addClass("is-valid")
            } else {
                confirmNewPassword.addClass("is-invalid")
            }
        }
    })

    function searchByCSID(e) {
        e.stopPropagation();
        e.preventDefault();
        if (e.which == 13) {
            redirectToPage(APP_CONFIG.ORIGIN_LOCATION + 'CallsPage?csid=' + e.target.value)
        } else {
            $("#searchInput").val($("#searchInput").val() + e.key)
        }
    }

    function resetModal() {
        document.getElementById("passwordForm").reset();
    }

})